<template>
  <div>
    <div class='d-flex justify-center mt-6 mb-6'>
      <h1>Fums</h1>
    </div>

    <v-row justify='center' class='text-center'>
      <v-col cols='12' md='8'>
        <h3>パスワードの再設定が完了しました</h3>
      </v-col>
    </v-row>
    <v-row justify='center' class='text-center'>
      <v-col cols='12' md='8'>
        <div>新しいパスワードに変更しました。</div>
        <div>アプリに戻って、新しいパスワードでログインしてください。</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    created() {
      window.location.href = 'cm-fums.app://jp.co.fums.url.scheme/login'
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>

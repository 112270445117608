<template>
  <div>
    <div class="d-flex justify-center mt-6 mb-6">
      <h1>Fums</h1>
    </div>

    <v-row>
      <v-col align='center'>
        {{ message }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      confirmStatus: 'wait'
    }),
    computed: {
      message() {
        let msg = ''
        switch (this.confirmStatus) {
          case 'wait':
            msg = '登録処理を行っています。このまま暫くお待ち下さい'
            break
          case 'success':
            msg = '登録が完了しました。アプリに戻ってログインしてください'
            break
          case 'faile':
            msg = '登録に失敗しました。管理者にお問い合わせください'
            break
        }
        return msg
      }
    },
    created() {
      this.authConfirm()
    },
    methods: {
      authConfirm() {
        const token = this.$route.query.confirmation_token
        this.axios.get(
          '/api/v1/auth/confirmation',
          { params: { confirmation_token: token } }
        ).then(() => {
          this.confirmStatus = 'success'
          window.location.href = 'cm-fums.app://jp.co.fums.url.scheme/login'
        }).catch(() => {
          this.confirmStatus = 'faile'
        })
      }
    },
    metaInfo() { return this.$t(`meta_tags.${this.$route.name}`) },
  }
</script>
